import React from "react";
import Solutions from "./Solutions";
import InnovationLab from "./InnovationLab";
import Impact from "./Impact";
import Awards from "./Awards";
import Alliances from "./Alliances";
import ContactUs from "./ContactUs";
import CarouselCustom from "../components/Carousel";
import Layout from "../components/Layout";

const meta = {
  title: "TruliaCare | Innovative Healthcare Solutions & Services Provider",
  description:
    "TruliCare is an award-winning healthcare solutions and services company with a team of experts who assist healthcare providers and vendors with advanced healthcare technology. Experience intelligent digital transformation and redefine your care experience with our TruliaCare solution suite, UHX platform, and Innovation Lab.",
  keywords:
    "On Field Care, Care Companion, Clinical Assistant, Event driven, real time, intelligent workflows, Integrates with your EHR & other systems with 200+ connectors, Ready to use digital care components, DICOM Ingester, intelligent  Digital Care Transformation, Innovation, mHealth, Unified Health Experience, UHX, HealthCare Product Innovation, Patient Engagement, Care Coordination, Continuous Care, Open Innovation, Agile, Emergent Design, Evolving Systems,business consulting services, strategic consulting, integrated business solutions, software product development, Intelligent digital care transformation, Aging and place, Hospice",
  canonical: "https://www.truliacare.com",
};

const HomePage = () => {
  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      canonical={meta.canonical}
    >
      <CarouselCustom interval={3000} />
      <Solutions />
      <InnovationLab />
      <Impact />
      <Awards />
      <Alliances />
      <ContactUs />
    </Layout>
  );
};

export default HomePage;
