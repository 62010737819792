import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import useCounter from "../hooks/useCounter";

const counterVariants = {
  offscreen: {
    opacity: 0,
    scale: 0.8, // Start scaled down
  },
  onscreen: {
    opacity: 1,
    scale: 1, // Scale up to original size
    transition: {
      duration: 1,
    },
  },
};

const AnimatedCounter = ({ maxCount, interval, className, id, suffix }) => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);
  const count = useCounter(maxCount, interval, inView);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setInView(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check initial position

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.div
      ref={ref}
      className={className}
      id={id}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={counterVariants}
    >
      {count}
      {suffix}
    </motion.div>
  );
};

export default AnimatedCounter;
