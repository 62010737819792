import React, { useEffect, useState } from "react";
import Cards from "../../components/Cards";
import scheduler from "../../assets/hospice/Intelligent_scheduling.svg";
import timeVisibility from "../../assets/hospice/Real_time_visibility.svg";
import televisit from "../../assets/hospice/Televisits.svg";
import hospiceIcon1 from "../../assets/care-coordination/04.png";
import hospiceIcon2 from "../../assets/care-coordination/05.png";

import { motion } from "framer-motion";

import "./careCoordination.css";
import "../../css/cards.css";
import CareFeatures from "./CareFeatures";
import { scroller } from "react-scroll";
import Layout from "../../components/Layout";
import axios from "axios";

const careData = [
  {
    id: "1",
    image: scheduler,
    desc: "iScheduler",
  },
  {
    id: "2",
    image: timeVisibility,
    desc: "Real time visibility",
  },
  {
    id: "3",
    image: televisit,
    desc: "TeleVisit",
  },
];

const leftVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};
const rightVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};
const meta = {
  title: "Hospice and Homecare Solution ",
  description:
    "Explore complete homecare and hospice solutions designed to transform patient care throughout the care journey. Our healthcare solutions optimize care operations, improve coordination among staff, and ensure personalized, high-quality support for both patients and caregivers.",
  keywords:
    "Care companion, patient engagement solution, patient engagement software, Homecare solution, Companion Services, Virtual companion solutions, prime home health and companion care, healthcare engagement solutions, healthcare engagement solutions, health engagement platform, digital patient engagement software, HIPAA compliant",
  abstract:
    "Kratin's customizable hospice and homecare solutions enhance care by empowering on-field teams with real-time mobile workflows. By integrating with EHR, call centers, and pharmacies, they improve delivery and coordination.",
  canonical: "https://truliacare.com/hospice-homecare",
};

const Hospice = () => {
  const [hospiceData, setHospiceData] = useState([]);

  const handleCardClick = (featureId) => {
    scroller.scrollTo(featureId, {
      duration: 500,
      offset: -200,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchCareFeature = async () => {
      const response = await axios.get(
        "https://tbuhx03.truliacare.com/cms/api/homecare-hospices"
      );
      const hospiceHomeCareData = response?.data?.data.map(
        (item) => item.attributes.hospice
      );
      const data = hospiceHomeCareData[0];
      setHospiceData(data);
    };
    fetchCareFeature();
  }, []);
  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      abstract={meta.abstract}
      canonical={meta.canonical}
    >
      <div className="care-coordination-overview">
        <div className="coordination-title-wrapper">
          <section>
            <div className="container care-container" style={{ width: "100%" }}>
              <h2 className="care-coordination-title">
                Unifying Experience For Homecare & Hospice
              </h2>
              <div className="row">
                <div
                  className="col-sm-6"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <div style={{ margin: "5% auto" }}>
                    {/* add animation */}
                    <motion.p
                      className="care-coordination-subtitle"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: true, amount: 0.8 }}
                      variants={leftVariants}
                    >
                      TruliaCare assists in improving care delivery by
                      empowering the field care team with real-time workflows
                      delivered via mobile applications. The solution can be
                      customized based on your specific homecare needs. It
                      integrates with existing IT systems, including EHR, call
                      centers, pharmacies, DME, etc.
                    </motion.p>
                  </div>
                </div>
                <motion.div
                  className="care-video col-sm-6"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                  variants={rightVariants}
                >
                  <div className="coordination-container">
                    <div className="play">
                      <iframe
                        height="200"
                        src="https://www.youtube.com/embed/Db8vBuN7Ghw?si=xof-moR8SDLs9sg-"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="container " style={{ marginTop: "40px" }}>
              <div className="row hospice-card">
                {careData.map((data) => (
                  <Cards
                    image={data.image}
                    description={data.desc}
                    index={data.id}
                    key={data.id}
                    onCardClick={handleCardClick}
                  />
                ))}
              </div>
            </div>
            {/* Innovation Images */}
            <div className="row">
              <div className="innovation col-6">
                <img
                  src={hospiceIcon1}
                  alt="Innovation and engineering lab"
                  className="innovation-image"
                />
              </div>
              <div className="innovation col-6">
                <img
                  src={hospiceIcon2}
                  alt="Integration with existing systems"
                  className="innovation-image"
                />
              </div>
            </div>
          </section>
          <div className="container" style={{ marginTop: "40px" }}>
            <h2 className="main-title-hospice">Features</h2>
          </div>
        </div>
        <CareFeatures careFeature={hospiceData} />
      </div>
    </Layout>
  );
};

export default Hospice;
