import React, { useState } from "react";
import Care_Coordination from "../assets/homepage/care.webp";
import care_companion from "../assets/homepage/patientEngagement.webp";
import homecare_hospice from "../assets/homepage/hospice.webp";
import dicom_ingester from "../assets/homepage/DICOM.webp";
import { motion } from "framer-motion";
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Alert, Button, Form, Input } from "antd";
import emailjs from "emailjs-com";

import "../css/solutions.css";

const data = [
  {
    id: 1,
    img: Care_Coordination,
    link: "/care-coordination",
    description: "Care Coordination",
    alt: "Care Coordination Solution",
  },
  {
    id: 2,
    img: care_companion,
    link: "/care-companion",
    description: "Care Companion",
    alt: "Care Companion Solution",
  },
  {
    id: 3,
    img: homecare_hospice,
    link: "/hospice-homecare",
    description: "Homecare & Hospice",
    alt: "Hospice and Homecare Solution",
  },
  {
    id: 4,
    img: dicom_ingester,
    link: "/dicom-ingester",
    description: "DICOM ingester",
    alt: "Dicom Ingester Solution",
  },
];

const textVariants = {
  offscreen: {
    opacity: 0,
    x: "-50px",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const Solutions = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal(!showModal);
    setStatus("");
  };

  const handleDownload = async (values) => {
    setLoading(true);

    try {
      await emailjs.send(
        "service_4riccx9",
        "template_okvc40j",
        { email: values.email },
        "6epiJ_lyWXahSwkD5"
      );

      const link = document.createElement("a");
      link.href = "/docs/TruliaCare_Overview.pdf";
      link.download = "TruliaCare_Overview.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setStatus("success");
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error:", error.text);
      setStatus("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div className="overview">
          <div className="container title-wrapper">
            <div className="container solution-container">
              <h2 className="main-title " style={{ letterSpacing: "2.2px" }}>
                REDEFINING CARE EXPERIENCE WITH
              </h2>
              <h2 className="main-title ">
                INTELLIGENT DIGITAL TRANSFORMATION
              </h2>
              <p className="sol-title-desc" style={{ margin: "25px 0" }}>
                The Award-winning TruliaCare’s healthcare solutions suite
                provides real-time care workflows, data-driven insights,
                interactive dashboards, data security, and HIPAA compliance to
                home health, hospice care, homecare, and community care
                agencies, allowing for increased patient census, care team
                operational efficiency, and improved quality care at a lower
                cost. With over 200 interfaces, it connects effortlessly with
                existing clinical (EHR, EMR, PAC, and others) and back office IT
                systems (Active Directory, Time, and Attendance).
              </p>
              <p
                className="sub-title"
                style={{ marginLeft: "0", marginBottom: "1em" }}
                id="solutions"
              >
                TruliaCare = Maximum productivity + Happy Workforce + Satisfied
                Patients = Savings + Increased Census
              </p>
            </div>
          </div>
        </div>
        <section className="container adjustContainer">
          <div className="container adjust-solution-conatiner">
            <h2 className="main-title-truliacare">TruliaCare Suite</h2>
          </div>
          <div className="row" style={{ marginLeft: "0", marginRight: "0" }}>
            <div className="ompf-portfolio-wrapper">
              <div
                className="adjust-container ompf-portfolio ompf-sc-portfolio ompf-layout-fixed ompf-size-small ompf-preview-layout-full"
                data-fit-height="true"
              >
                <div className="row d-flex justify-content-center">
                  {data.map((data) => (
                    <div
                      className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 card-sol"
                      key={data.id}
                    >
                      <div
                        className="ompf-portfolio-thumb ompf-size-small ompf-with-link"
                        id="post-20673"
                      >
                        <Link to={data.link} className="adjust-height">
                          <span className="ompf-pic-wrapper">
                            <span
                              className="ompf-pic"
                              style={{ paddingTop: "66.6666666667%" }}
                            >
                              <span className="ompf-pic-inner">
                                <img
                                  src={data.img}
                                  alt={data.alt}
                                  className="lazyload"
                                  style={{
                                    display: "block",
                                    width: "100%",
                                    height: "auto",
                                  }}
                                  width="200" // Add explicit width
                                  height="133" // Add explicit height
                                />
                              </span>
                            </span>
                          </span>
                          <span className="ompf-desc-wrapper">
                            <span className="ompf-desc">
                              <span className="ompf-title">
                                {data.description}
                              </span>
                            </span>
                          </span>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <motion.p
            className="solution-feature-desc fadeInLeft "
            style={{
              overflow: "hidden",
              marginLeft: "0.7em",
              marginRight: "1em",
            }}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants={textVariants}
          >
            Care models are transforming from reactive and episodic to
            proactive, intelligent, and continuous care. TruliaCare Solutions
            Suite plays a vital role in this changing paradigm of care by
            identifying care gaps, improving care team productivity, providing
            the best possible patient outcomes, and ensuring provider
            satisfaction. As a virtual companion, the TruliaCare suite helps
            patients maintain their well-being.
          </motion.p>
          <motion.p
            className="solution-feature-desc fadeInLeft "
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants={textVariants}
            style={{
              marginLeft: "0.7em",
              marginRight: "1em",
            }}
          >
            Discover how Truliacare is revolutionizing care delivery.
            <span className="click-here" onClick={handleShowModal}>
              Download our brief to learn more.
            </span>
          </motion.p>
        </section>
      </div>
      {showModal && (
        <div className="download-pdf">
          <div
            className="download-modal"
            style={{
              width: "400px",
              margin: "0 auto",
              padding: "20px",
              position: "relative",
            }}
          >
            <h3 className="download-modal-heading">Download Your Copy</h3>
            <CloseOutlined
              className="download-modal-close"
              onClick={handleCloseModal}
              style={{ position: "absolute", top: "10px", right: "10px" }}
            />
            <Form
              layout="vertical"
              onFinish={handleDownload}
              style={{
                marginBottom: "0px",
                padding: "0px",
                fontFamily: "Open Sans",
              }}
            >
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  { required: true, message: "Please enter your email" },
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input style={{ padding: "0.5em", marginTop: "-10px" }} />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                className="download-modal-button mb-3"
                loading={loading}
                disabled={isSubmitted}
              >
                Download
              </Button>
            </Form>
            <div style={{ minHeight: "50px", paddingTop: "10px" }}>
              {status === "success" && (
                <Alert
                  message="Downloaded"
                  type="success"
                  showIcon
                  className="mt-2"
                />
              )}
              {status === "error" && (
                <Alert
                  message="Failed to download"
                  type="error"
                  showIcon
                  className="mt-2"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Solutions;
