import React from "react";

import "../css/impact.css";
import AnimatedCounter from "../components/AnimatedCounter";

const Impact = () => {
  return (
    <div className="conatiner impact-container" id="impact">
      <div className="impact-title-wrapper">
        <div>
          <div className="title-font">Our Impact</div>
        </div>
        <div className="stat" style={{ marginLeft: "1em" }}>
          <div
            style={{
              borderTop: "2px solid lightgrey",
              borderLeft: "2px solid lightgrey",
            }}
          >
            <div className="descriptionStat">
              <div className="number">
                <AnimatedCounter
                  maxCount={300}
                  interval={1000 / 300}
                  className="num1"
                  id="exactNum"
                />
                <div className="num">M+</div>
              </div>
            </div>
            <div className="sDetails">Events have been processed this year</div>
          </div>
          {/* </div> */}
          {/* <div className="stat"> */}
          <div>
            <div className="descriptionStat">
              <div className="number">
                <AnimatedCounter
                  maxCount={30}
                  interval={1000 / 30}
                  className="num1"
                  id="exactNum"
                />
                <div className="num">%</div>
              </div>
            </div>
            <div className="sDetails">improvement in patient satisfaction</div>
          </div>
          <div>
            <div className="descriptionStat">
              <div className="number">
                <AnimatedCounter
                  maxCount={23}
                  interval={1000 / 23}
                  className="num1"
                  id="exactNum"
                />
                <div className="num">%</div>
              </div>
            </div>
            <div className="sDetails">Increase in Emergent response time</div>
          </div>
          <div
            style={{
              borderBottom: "2px solid lightgrey",
              borderRight: "2px solid lightgrey",
            }}
          >
            <div className="descriptionStat">
              <div className="number">
                <AnimatedCounter
                  maxCount={6}
                  interval={1000 / 6}
                  className="num1"
                  id="exactNum"
                />
                <div className="num">X</div>
              </div>
            </div>
            <div className="sDetails">improvement in referal processing</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impact;
