import React, { useCallback, useEffect, useRef, useState } from "react";
import "../css/carousel.css";
import { Carousel } from "antd";
import axios from "axios";

const contentStyle = {
  width: "100%",
  height: "auto",
  color: "#fff",
  lineHeight: "160px",
  objectFit: "cover",
  textAlign: "center",
};

const CustomDot = React.memo(({ index, isActive, onClick }) => {
  return (
    <div
      style={{
        width: "6px",
        height: "6px",
        borderRadius: "50%",
        backgroundColor: isActive ? "#3b3939" : "#cccccc",
        margin: "0 5px",
        cursor: "pointer",
        transition: "background-color 0.3s",
      }}
      onClick={() => onClick(index)}
    />
  );
});

const preloadImages = (images) => {
  images.forEach((item) => {
    const img = new Image();
    img.src = item?.attributes?.url;
  });
};

const CarouselCustom = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [carouselImages, setCarouselImages] = useState([]);
  const [loader, setLoader] = useState(true);
  const carouselRef = useRef(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          "https://tbuhx03.truliacare.com/cms/api/truliacare-carousel?populate=*"
        );
        const images = response?.data?.data?.attributes?.carousel?.data || [];
        setCarouselImages(images);
        preloadImages(images);
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoader(false);
      }
    };
    fetchImages();
  }, []);
  // console.log(carouselImages);

  const handleDotClick = useCallback((index) => {
    setCurrentSlide(index);
    carouselRef.current.goTo(index, true);
  }, []);

  return (
    <div style={{ marginBottom: "15px" }}>
      {loader ? (
        <div className="carousel_loading">
          <span className="loading__dot"></span>
          <span className="loading__dot"></span>
          <span className="loading__dot"></span>
        </div>
      ) : (
        <>
          <Carousel
            ref={carouselRef}
            autoplay
            dotPosition="bottom"
            draggable={true}
            dots={false}
            beforeChange={(from, to) => setCurrentSlide(to)}
          >
            {carouselImages.map((item, index) => (
              <div key={index}>
                <img
                  style={contentStyle}
                  srcSet={`https://tbuhx03.truliacare.com/cms${item?.attributes?.url}?w=480 480w, https://tbuhx03.truliacare.com/cms${item?.attributes?.url}?w=768 768w, https://tbuhx03.truliacare.com/cms${item?.attributes?.url}?w=1200 1200w`}
                  src={`https://tbuhx03.truliacare.com/cms${item?.attributes?.url}`}
                  alt={item?.attributes?.alternativeText}
                  loading="lazy"
                />
              </div>
            ))}
          </Carousel>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {carouselImages.map((_, index) => (
              <CustomDot
                key={index}
                index={index}
                isActive={index === currentSlide}
                onClick={handleDotClick}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CarouselCustom;
