import React, { useState } from "react";
import Innovation_Lab from "../assets/homepage/Innovation_Lab.webp";
import { motion } from "framer-motion";

import "../css/innovation.css";
import { Link } from "react-router-dom";

const leftVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};
const rightVariants = {
  offscreen: {
    opacity: 0,
    x: "-5%",
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
    },
  },
};
const InnovationLab = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  return (
    <>
      <div className="main-section alabaster " id="innovation">
        <div className="">
          <div className="container container-innovation">
            <h2
              className="innovation-main-title"
              style={{ margin: "0", marginTop: "40px", marginBottom: "30px" }}
            >
              Innovation Lab
            </h2>
            <div className="row">
              <motion.figure
                className="col-lg-5 col-sm-4 fade-in-left"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={leftVariants}
                style={{ marginBottom: "20px" }}
              >
                <div className="items-container">
                  <div className="image">
                    <img
                      src={Innovation_Lab}
                      alt="Innovation Lab (Services)"
                      style={{ width: "100%", height: "100%" }}
                      className="innovation-img"
                      onClick={toggleFullScreen}
                      loading="lazy"
                    />
                  </div>
                </div>
              </motion.figure>
              {isFullScreen && (
                <div className="full-screen " onClick={toggleFullScreen}>
                  <img
                    src={Innovation_Lab}
                    alt="Innovation Lab (Services)"
                    className="fullscreen-image"
                  />
                </div>
              )}
              <motion.div
                className="col-lg-7 col-sm-8 featured-work"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={rightVariants}
              >
                <div className="featured-box">
                  <ul className="fade-in-right" style={{ marginLeft: "1em" }}>
                    <li>
                      Strategic consulting for identifying opportunities for
                      improving care management with healthcare IT solutions
                      along with their outcomes.
                    </li>
                    <li>
                      Rapid prototyping team to experiment meaningful use cases
                      using technology advancements like machine learning, bots,
                      voice, IOT, and others in a very cost-effective manner.
                    </li>
                    <li>
                      Extended IT team to convert opportunities into deployed
                      solutions in a matter of weeks and maintain them
                    </li>
                  </ul>
                </div>
                <br />
              </motion.div>
              <div style={{ textAlign: "right" }}>
                <Link to="/innovation-lab" className="read-more">
                  Know more...
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InnovationLab;
