import React, { useState } from "react";
import { Alert, Button, Form, Input, Select, Spin } from "antd";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import countryCode from "../json/countryCodes.json";
import demoIcon from "../assets/logo/icon.png";
import emailjs from "emailjs-com";

import "../css/requestDemo.css";

const { Option } = Select;

const ReuestDemo = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    companyName: "",
    role: "",
    interests: [],
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleRequestDemo = (values) => {
    setLoading(true);

    const updatedFormData = {
      name: values.name,
      email: values.email,
      contact: values.contactNumber,
      companyName: values.companyName,
      role: values.role,
      interests: values.selectedInterest,
    };

    // Use the updated form data directly
    emailjs
      .send(
        "service_4riccx9",
        "template_xkc4oko",
        updatedFormData,
        "6epiJ_lyWXahSwkD5"
      )
      .then(
        (result) => {
          // console.log(result.text);
          setStatus("success");
          setIsSubmitted(true);
        },
        (error) => {
          console.log(error.text);
          setStatus("error");
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const prefixSelector = (
    <Select
      defaultValue="+1"
      showSearch
      style={{ width: 100 }}
      dropdownStyle={{ minWidth: 300 }}
    >
      {countryCode.map((code) => (
        <Option
          value={code.dial_code}
          style={{ width: "100%" }}
          key={code.dial_code}
        >
          {code.dial_code} {code.code}
        </Option>
      ))}
    </Select>
  );

  return (
    <div className="modal-content demoModal">
      <div className="modal-body" style={{ padding: "10px" }}>
        <div className="container">
          <div className="row" style={{ color: "white" }}>
            <div className="col-md-7">
              <p className="powered">
                <b>Powered By</b>
              </p>

              <div className="row">
                <img
                  src={demoIcon}
                  alt="kratinTrulicareImage"
                  className="demo-icon"
                />
              </div>
              <div>
                <p className="greetings-text">
                  <span className="heading">
                    Ready to see TruliaCare in action?
                  </span>
                </p>
                <p className="greetings-text">
                  We can't wait to show you how easy it is to optimize care
                  delivery using your existing clinical systems.
                </p>
                <p className="greetings-footer">
                  Please fill in the form and get started with our platform in
                  no time.
                </p>
              </div>
            </div>
            <div className="col-md-5" style={{ padding: 0 }}>
              <div className="details-card">
                <div className="row align-logo">
                  <div className="user-circle">
                    <i
                      className="fa fa-user-circle-o demo-user-icon"
                      aria-hidden="true"
                      style={{
                        color: "#0179cc",
                        fontSize: "1.5em",
                        paddingTop: "0.5em",
                      }}
                    ></i>
                  </div>
                  <p className="form-header">Request a demo</p>
                  <p className="form-text">
                    We just need some information from you before scheduling a
                    Demo
                  </p>
                  <Form
                    layout="vertical"
                    className="details-form"
                    onFinish={handleRequestDemo}
                  >
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        { required: true, message: "Please enter your name" },
                      ]}
                    >
                      <Input prefix={<UserOutlined />} placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="Email Address*"
                      rules={[
                        { required: true, message: "Please enter your email" },
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined />}
                        placeholder="Email Address"
                      />
                    </Form.Item>
                    <Form.Item name="contactNumber" label="Contact Number">
                      <Input
                        addonBefore={prefixSelector}
                        prefix={<PhoneOutlined />}
                        placeholder="Contact Number"
                      />
                    </Form.Item>
                    <Form.Item name="companyName" label="Company Name">
                      <Input
                        prefix={<HomeOutlined />}
                        placeholder="Company Name"
                      />
                    </Form.Item>
                    <Form.Item name="role" label="Role">
                      <Input prefix={<SolutionOutlined />} placeholder="Role" />
                    </Form.Item>
                    <Form.Item name="selectedInterest" label="Interested Area">
                      <Select
                        mode="multiple"
                        placeholder="Select Interested Areas"
                      >
                        <Option value="Care Coordination">
                          Care Coordination
                        </Option>
                        <Option value="Patient Engagement">
                          Care Companion
                        </Option>
                        <Option value="Hospice">Hospice and Homecare</Option>
                        <Option value="Innovation">Innovation Lab</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                        disabled={isSubmitted}
                      >
                        Request Demo
                      </Button>
                    </Form.Item>
                    <div className=" d-flex align-items-center justify-content-center mb-3">
                      {loading && <Spin />}
                    </div>
                    {status === "success" && (
                      <Alert
                        message="Request Submitted Successfully"
                        type="success"
                        className="mb-3"
                        showIcon
                      />
                    )}
                    {status === "error" && (
                      <Alert
                        message="Failed to submit request"
                        type="error"
                        showIcon
                        className="mb-3"
                      />
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReuestDemo;
