import { useState, useEffect } from "react";

function useCounter(maxCount, interval, start) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timer;

    if (start && count < maxCount) {
      timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount < maxCount) {
            return prevCount + 1;
          } else {
            clearInterval(timer);
            return prevCount;
          }
        });
      }, interval);
    }

    return () => clearInterval(timer);
  }, [count, maxCount, interval, start]);

  return count;
}

export default useCounter;
