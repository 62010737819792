import React, { useEffect, useState } from "react";

import "../css/footer.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const path = location.pathname;

  //   const handleHashChange = () => {
  //     if (path === "/") {
  //       setActiveTab("home");
  //     } else if (
  //       path.startsWith("/care-coordination") ||
  //       path.startsWith("/care-companion") ||
  //       path.startsWith("/hospice") ||
  //       path.startsWith("/dicom")
  //     ) {
  //       setActiveTab("solutions");
  //     } else if (path.startsWith("/innovation-lab")) {
  //       setActiveTab("innovation");
  //     } else if (path.startsWith("/blogs")) {
  //       setActiveTab("blogs");
  //     }
  //   };

  //   handleHashChange();

  //   window.addEventListener("hashchange", handleHashChange);

  //   return () => {
  //     window.removeEventListener("hashchange", handleHashChange);
  //   };
  // }, [location]);

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  //   if (tab !== "home") {
  //     document.getElementById(tab).scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const path = location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);

    if (path === "/") {
      setActiveTab("home");
    } else if (
      path.startsWith("/care-coordination") ||
      path.startsWith("/care-companion") ||
      path.startsWith("/hospice") ||
      path.startsWith("/dicom")
    ) {
      setActiveTab("solutions");
    } else if (path.startsWith("/innovation-lab")) {
      setActiveTab("innovation");
    } else if (path.startsWith("/blogs")) {
      setActiveTab("blogs");
    }
  }, [location]);

  const handleNavigation = (tab, id) => {
    handleTabClick(tab);
    if (path !== "/") {
      navigate("/", { state: { scrollTo: id } });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className="footer-container">
      <div id="footer">
        <div className="footer-about">
          <h2>About Kratin</h2>
          <p>
            Established in 2011, Kratin is a healthcare-focused company enabling
            transformative changes with wide spectrum of solutions across care
            continuum for care consumer and care team.
            <br />
            Our team is passionate about bringing positive change to the
            people's lives using their wide technical expertise and experience.
            Living with cutting edge technologies, we love to seek new
            challenges bringing growth opportunities for our clients with the
            right solutions.
          </p>
        </div>
        <div className="footer-connect">
          <h2 style={{ marginBottom: "10px" }}>Connect</h2>
          <ul className="socials">
            {/* <Link
              to="https://www.linkedin.com/showcase/truliacare"
              target="_blank"
            >
              <li className="social-icon" id="socialFacebook"></li>
            </Link> */}
            <Link
              // to="https://www.linkedin.com/showcase/truliacare"
              to="https://x.com/TruliaCare"
              target="_blank"
            >
              <li className="social-icon" id="socialLinkedIn"></li>
            </Link>
            <Link
              to="https://www.linkedin.com/showcase/truliacare"
              target="_blank"
            >
              <li className="social-icon" id="socialTwitter"></li>
            </Link>
            <Link to="https://www.youtube.com/@truliacare4963" target="_blank">
              <li className="social-icon" id="socialYoutube"></li>
            </Link>
          </ul>
          <div className="perspectives">
            <p>Stay in touch with us.</p>
          </div>
        </div>
        {/* <div className="twitter-feed">
          <div className="post-box">
            <div className="post-header">
              <img src="" alt="postimg" className="profile-pic" />
              <div className="post-info">
                <strong>Truliacare</strong>
                <span>@truliacare</span>
              </div>
            </div>
            <div className="post-content">
              <div className="post">
                <p>Content</p>
                <span className="timestamp">Time</span>
              </div>
              <button className="view-button">View on LinkedIn</button>
            </div>
          </div>
        </div> */}
        <div className="footer-nav">
          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <Link
                to="/"
                className={activeTab === "home" ? "active" : ""}
                onClick={() => handleTabClick("home")}
              >
                Home
              </Link>
            </li>
            <li className="footer-nav-item">
              <a
                className={activeTab === "solutions" ? "active" : ""}
                href="#solutions"
                onClick={() => handleNavigation("solutions", "solutions")}
              >
                Solutions
              </a>
            </li>
            <li className="footer-nav-item">
              <a
                href={path === "/" ? "#innovation" : "/innovation-lab"}
                className={activeTab === "innovation" ? "active" : ""}
                onClick={() => handleTabClick("innovation")}
              >
                Innovation Lab
              </a>
            </li>
            <li className="footer-nav-item">
              <a
                href="#impact"
                className={activeTab === "impact" ? "active" : ""}
                onClick={() => handleNavigation("about", "impact")}
              >
                About Us
              </a>
            </li>
            <li className="footer-nav-item">
              <a
                href="#contact"
                onClick={() => handleNavigation("contact", "contact")}
                className={activeTab === "contact" ? "active" : ""}
              >
                Contact Us
              </a>
            </li>
            <li className="footer-nav-item">
              <Link
                to={"/privacy-policy"}
                className={activeTab === "privacy-policy" ? "active" : ""}
                // onClick={() => handleTabClick("privacy-policy")}
              >
                Privacy policy
              </Link>
            </li>
          </ul>
          <p>&copy; 2024 Kratin LLC</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
