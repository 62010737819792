import React from "react";
import "../css/cards.css";

const Cards = ({ image, description, index, onCardClick }) => {
  return (
    <div className="card-button" onClick={() => onCardClick(index)}>
      <div className="card-content">
        <img className="maintain" src={image} alt="" />
        <div className="card-desc">{description}</div>
      </div>
    </div>
  );
};

export default Cards;
