import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Modal } from "antd";
import ReuestDemo from "./ReuestDemo";
import "../css/requestDemo.css";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
  const [visible, setVisible] = useState(false);
  const [scrollVisible, setScrollVisible] = useState(false);
  const location = useLocation();

  const showModal = () => setVisible(true);
  const handleClose = () => setVisible(false);
  const path = location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      setScrollVisible(window.scrollY > window.innerHeight * 1);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <meta name="abstract" content={props.abstract} />
        <link rel="canonical" href={props.canonical} />
      </Helmet>
      <Header />
      {path !== "/careers" && (
        <div className="demoCard" onClick={showModal}>
          <p className="demoCardText">REQUEST DEMO</p>
        </div>
      )}

      <main>{props.children}</main>
      <Footer />
      {scrollVisible && (
        <div className="scrollUp" onClick={scrollToTop}>
          <i className="fa fa-angle-double-up doubleup"></i>
        </div>
      )}
      <Modal
        title={null}
        open={visible}
        onCancel={handleClose}
        width={1000}
        footer={null}
      >
        <div style={{ backgroundColor: "#0179cc" }}>
          <ReuestDemo />
        </div>
      </Modal>
    </>
  );
};

Layout.defaultProps = {
  title: "TruliaCare | Innovative Healthcare Solutions & Services Provider",
  description:
    "TruliCare is an award-winning healthcare solutions and services company with a team of experts who assist healthcare providers and vendors with advanced healthcare technology. Experience intelligent digital transformation and redefine your care experience with our TruliaCare solution suite, UHX platform, and Innovation Lab.",
  keywords:
    "On Field Care, Care Companion, Clinical Assistant, Event driven, real time, intelligent workflows, Integrates with your EHR & other systems with 200+ connectors, Ready to use digital care components, DICOM Ingester, intelligent  Digital Care Transformation, Innovation, mHealth, Unified Health Experience, UHX, HealthCare Product Innovation, Patient Engagement, Care Coordination, Continuous Care, Open Innovation, Agile, Emergent Design, Evolving Systems,business consulting services, strategic consulting, integrated business solutions, software product development, Intelligent digital care transformation, Aging and place, Hospice",
  canonical: "https://www.truliacare.com",
};

export default Layout;
