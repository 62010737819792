import React from "react";
import Nuance from "../assets/homepage/Nuance_Communications.webp";
import Allscripts from "../assets/homepage/allscripts.webp";

import "../css/alliances.css";

const Alliances = () => {
  return (
    <div className="alliance">
      <div className="container alliance-container">
        <h2 className="alliance-main-title">Alliances</h2>
        <div className="row" style={{ alignItems: "baseline" }}>
          <div
            className="col-12 col-sm-6 col-md-6 col-lg-6 alliance-img"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            <img
              src={Nuance}
              alt="nuance_logo"
              style={{ height: "110%", width: "40%" }}
              loading="lazy"
            />
          </div>
          <div
            className="col-12 col-sm-6 col-md-6 col-lg-6"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            <img
              src={Allscripts}
              alt="allscripts_logo"
              style={{ height: "110%", width: "40%" }}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alliances;
