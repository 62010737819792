import React from "react";
import award1 from "../assets/awards/award.webp";
import award2 from "../assets/awards/award2.webp";
import award3 from "../assets/awards/award3.webp";
import "../css/awards.css";

const Awards = () => {
  return (
    <div className="awards-section" style={{ paddingTop: "3px" }}>
      <div className="container">
        <h2
          className="awards-main-title"
          style={{
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          Awards and Recognitions
        </h2>
      </div>
      <div id="processRowl">
        <div className="entry">
          <div
            className="award-image"
            //   style={{ width: "400", height: "248" }}
          >
            <img
              src={award1}
              alt="Healthcare Excellence Award in Innovation &amp; Advances"
              loading="lazy"
            />
          </div>
          <div className="award-body">
            <h2 className="award-title">
              Healthcare Excellence Award in Innovation &amp; Advances
            </h2>
          </div>
        </div>
        <div className="entry">
          <div
            className="award-image"
            //   style={{ width: "400", height: "248" }}
          >
            <img
              src={award2}
              alt="Mobile Healthcare Startup of the Year - 2014 by Siliconindia"
              loading="lazy"
            />
          </div>
          <div className="award-body">
            <h2 className="award-title">
              Mobile Healthcare Startup of the Year - 2014 by Siliconindia
            </h2>
          </div>
        </div>
        <div className="entry">
          <div
            className="award-image"
            //   style={{ width: "400", height: "248" }}
          >
            <img
              src={award3}
              alt="Healthcare Excellence Award in IT Innovation & Advances"
              loading="lazy"
            />
          </div>
          <div className="award-body">
            <h2 className="award-title">
              Healthcare Excellence Award in IT Innovation & Advances
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
